import "./technique.scss";

import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import { PageContext } from "gatsby/internal";
import * as React from "react";
import { useState } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Reviews from "../../components/GMB/Reviews";
import { SEO } from "../../components/SEO/SEO";
import TechniqueMarkupSchema from "../../components/SEO/TechniquesMarkupSchema";
import { getOtherServiceMapped } from "../../contexts/othersServices/infrastructure/otherServiceSanityDataMapper";
import { Technique } from "../../contexts/techniques/domain/Technique";
import { getTechniqueMapped } from "../../contexts/techniques/infrastructure/techniqueSanityDataMapper";
import Banner from "../../views/shared/banner/Banner";
import Hero from "../../views/shared/hero/Hero";
import Layout from "../../views/shared/layout/layout";
import ModalBook from "../../views/shared/modalForm/ModalBook";
import PriceCard from "../../views/techniques/PriceCard/PriceCard";
import WeTechniques from "../../views/techniques/slider/WeTechniques";

interface TechniquePageContext extends PageContext {
  technique: Technique;
  id: string;
}

const PageTechnique: React.FC<TechniquePageContext> = (
  props: TechniquePageContext
) => {
  const { technique } = props.pageContext;
  const [has_modal, setHasModal] = useState(false);
  const simplybookId: number = technique.simplybookId || 0;

  const { projectId, dataset } = props.data.site.siteMetadata;

  const services = [];
  // const otherServices = [];

  // const techniques: Technique[] = [];
  // const othersTechniques: Technique[] = [];

  // props.data.allSanityTechnique.nodes.forEach((_technique) => {
  //   othersTechniques.push(getTechniqueMapped(_technique));
  // });

  props.data.allSanityTechnique.nodes.forEach((_technique) => {
    //techniques.push(getTechniqueMapped(_technique));
    services.push(getTechniqueMapped(_technique));
  });

  props.data.allSanityOtherServices.nodes.forEach((_service) => {
    services.push(getOtherServiceMapped(_service));
  });
  const advantagesList = [];
  if (technique?.advantages) {
    technique?.advantages?.list.forEach((advantage, index) => {
      advantagesList.push(
        <p key={index} className={"a-advantages__text"}>
          {advantage}
        </p>
      );
    });
  }

  return (
    <>
      {has_modal && (
        <ModalBook
          title={"Reserva cita"}
          is_active={setHasModal}
          simplyBookingId={simplybookId}
          section={"TECHNIQUE"}
        />
      )}

      <Layout>
        <TechniqueMarkupSchema
          service={technique.seo.title}
          description={technique.seo.description}
          image={technique.photo.asset.gatsbyImageData.images.fallback.src}
          slug={technique.slug}
        />
        <SEO
          title={`${technique.seo.title}`}
          description={technique.seo.description}
          canonical={technique.slug}
          image={technique.photo.asset.gatsbyImageData.images.fallback.src}
        />
        <div className={"o-hero-technique"}>
          <Hero
            title={`${technique.title}`}
            subtitle={`${technique.subtitle}`}
            photo={
              technique.background.asset.gatsbyImageData.images.fallback.src
            }
            photo_alt={technique.background_alt}
            color={"#A3BCC4"}
          >
            <button className={"a-button"} onClick={() => setHasModal(true)}>
              Pide cita ahora
            </button>
          </Hero>
          {technique.heroPrice && <PriceCard technique={technique} />}
        </div>
        <section className={"o-technique"}>
          <div className={"m-technique__desc grid-x2 grid-x2--break-pro"}>
            <div>
              <h2 className={"h3"}>Qué es</h2>
              <p>{technique.faq.what}</p>
            </div>
            <div>
              <h2 className={"h3"}>Cómo funciona</h2>
              <p>{technique.faq.how}</p>
            </div>
          </div>
          <div
            className={
              "container o-box o-box--grey-blue is-border-top-right-desktop is-border-top-right-mobile grid-x2 grid-x2--break-pro"
            }
          >
            <div
              className={"a-box__image is-border-top-right-mobile"}
              style={{
                backgroundImage: `url(${technique.photo.asset.gatsbyImageData.images.fallback.src})`,
              }}
            />
            <div className={"m-box is-border-top-right-desktop"}>
              <BlockContent
                blocks={technique.content}
                projectId={projectId}
                dataset={dataset}
                className={"m-box__text"}
              />
            </div>
          </div>
        </section>
        {technique.advantages && (
          <section className={"o-advantages"}>
            <div className={"m-advantages-list"}>
              <h2 className={"a-advantages__title"}>
                {technique.advantages.title}
              </h2>
              {advantagesList}
            </div>
          </section>
        )}
        <Banner
          title={"Vuelve a sentirte bien con Obelion"}
          background={
            technique.background.asset.gatsbyImageData.images.fallback.src
          }
        >
          <div className={"p-top"}>
{/*            <a
              href={
                "https://www.treebole-clinicas.net/es/customer/requestappo/cusrc/g84fzxc8vvaguap"
              }
              target={"_blank"}
              rel={"noopener noreferrer nofollow"}
              className={"a-button"}
            >
              Pide cita ahora
            </a>*/}
          <button className={"a-button"} onClick={() => setHasModal(true)}>
              Pide cita ahora
            </button>
          </div>
        </Banner>
        <WeTechniques services={services} title={"Otros servicios Obelion"} />
        <Reviews />
      </Layout>
    </>
  );
};
export default PageTechnique;

export const query = graphql`
  query templateTechniqueQuery(
    $id: String = "a801dc78-10f6-4024-8d2d-7c7fde3ea9a6"
  ) {
    allSanityTechnique(filter: { _id: { ne: $id } }) {
      nodes {
        ...Technique
      }
    }
    allSanityOtherServices {
      nodes {
        ...OtherService
      }
    }
    site {
      siteMetadata {
        projectId
        dataset
      }
    }
  }
`;
